import React from "react";
import { Row, Col } from "antd";
import "./index.css";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
      <Row className="cookie-policy-row">
        <Col span={24}>
          <span className="cookie-policy-sub-heading">What Are Cookies</span>
          <p className="cookie-policy-text">
            As is common practice with almost all professional websites, this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies.
          </p>
        </Col>
        <Col span={24}>
          <span className="cookie-policy-sub-heading">How We Use Cookies</span>
          <p className="cookie-policy-text">
            We use cookies for a variety of reasons detailed below.
            Unfortunately, in most cases, there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </p>
        </Col>
        <Col span={24}>
          <span className="cookie-policy-sub-heading">Disabling Cookies</span>
          <p className="cookie-policy-text">
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of this
            site. Therefore, it is recommended that you do not disable cookies.
          </p>
        </Col>
        <Col span={24}>
          <span className="cookie-policy-sub-heading">The Cookies We Set</span>
          <ul>
            <li className="cookie-policy-text">
              Account related cookies: If you create an account with us then we
              will use cookies for the management of the signup process and
              general administration. These cookies will usually be deleted when
              you log out however in some cases they may remain afterwards to
              remember your site preferences when logged out.
            </li>
            <li className="cookie-policy-text">
              Login related cookies: We use cookies when you are logged in so
              that we can remember this fact. This prevents you from having to
              log in every single time you visit a new page. These cookies are
              typically removed or cleared when you log out to ensure that you
              can only access restricted features and areas when logged in.
            </li>
            <li className="cookie-policy-text">
              Email newsletters related cookies: This site offers newsletter or
              email subscription services and cookies may be used to remember if
              you are already registered and whether to show certain
              notifications which might only be valid to subscribed/unsubscribed
              users.
            </li>
            <li className="cookie-policy-text">
              Orders processing related cookies: This site offers e-commerce or
              payment facilities and some cookies are essential to ensure that
              your order is remembered between pages so that we can process it
              properly.
            </li>
            <li className="cookie-policy-text">
              Surveys related cookies: From time to time we offer user surveys
              and questionnaires to provide you with interesting insights,
              helpful tools, or to understand our user base more accurately.
              These surveys may use cookies to remember who has already taken
              part in a survey or to provide you with accurate results after you
              change pages.
            </li>
            <li className="cookie-policy-text">
              Forms related cookies: When you submit data to through a form such
              as those found on contact pages or comment forms cookies may be
              set to remember your user details for future correspondence.
            </li>
          </ul>
        </Col>
        <Col span={24}>
          <span className="cookie-policy-sub-heading">Third Party Cookies</span>
          <p className="cookie-policy-text">
            In some special cases we also use cookies provided by trusted third
            parties. The following section details which third party cookies you
            might encounter through this site.
          </p>
          <ul>
            <li className="cookie-policy-text">
              Analytics cookies: We also use cookies to help us test new
              advertisements, pages, features or new functionality of the
              website to see how our users react to them.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default CookiePolicy;
