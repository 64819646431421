import React from 'react';
import { Row, Col } from 'antd';
import './index.css'; 

const TermsAndConditions = () => {
    return (
        <div className="terms-conditions-container">
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>Welcome to Our Website!</span>
                    <p className='terms-policy-text'>These terms and conditions outline the rules and regulations for the use of Company Name's Website, located at www.example.com.</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>License to Use Website</span>
                    <p className='terms-policy-text'>Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material on Company Name. All intellectual property rights are reserved. You may access this from Company Name for your own personal use subjected to restrictions set in these terms and conditions.</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>You Must Not:</span>
                    <ul>
                        <li className='terms-policy-text'>Republish material from Company Name</li>
                        <li className='terms-policy-text'>Sell, rent, or sub-license material from Company Name</li>
                        <li className='terms-policy-text'>Reproduce, duplicate or copy material from Company Name</li>
                        <li className='terms-policy-text'>Redistribute content from Company Name</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>User Responsibilities</span>
                    <p className='terms-policy-text'>It is your responsibility to ensure that your use of our website complies with all applicable local, national, and international laws. You must not use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>Disclaimer</span>
                    <p className='terms-policy-text'>The materials on Company Name's website are provided on an 'as is' basis. Company Name makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Company Name does not make any representations concerning the accuracy or reliability of the use of the materials on its website or otherwise relating to such materials or any sites linked to this site.</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <span className='terms-policy-sub-heading'>Limitation of Liability</span>
                    <p className='terms-policy-text'>In no event shall Company Name, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract.  Company Name, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this site.</p>
                </Col>
            </Row>
        </div>
    );
};

export default TermsAndConditions;
